import type { HeadlineResponse } from '@on3/api';
import { ResizedImage } from 'components/Image/ResizedImage';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';

import styles from './NilResources.module.scss';

export const NilResourceItem = ({
  featuredImage,
  title,
  fullUrl,
  slug,
  primaryCategory,
}: HeadlineResponse) => {
  let baseUrl = primaryCategory?.fullUrl;

  if (baseUrl?.endsWith('/')) {
    baseUrl = baseUrl.slice(0, -1);
  }

  return (
    <li className={styles.articleItem}>
      {featuredImage && (
        <a className={styles.imageLink} href={`/nil/news/${slug}/`}>
          <ResizedImage
            className={styles.image}
            {...featuredImage}
            fit="cover"
            gravity="0.5x0.5"
            height={80}
            quality={90}
            width={80}
          />
        </a>
      )}
      <div className={styles.articleInfoContainer}>
        <TextLink
          className={styles.articleCategory}
          href={`${baseUrl}/news/`}
          underline="none"
          variant="subtitle1"
        >
          {primaryCategory?.name}
        </TextLink>
        <TextLink href={fullUrl} underline="none">
          <Text className={styles.articleTitle} truncate={60} variant="h5">
            {title}
          </Text>
        </TextLink>
      </div>
    </li>
  );
};
