import type { NilRankingResponseRelatedNilRankingResponseRelatedPagedData } from '@on3/api';
import { formatOrganizationType } from '@on3/ui-lib/utils/organization';
import { Link } from 'components/Link';

import styles from './NilSportWidget.module.scss';
import { NilSportWidgetItem } from './NilSportWidgetItem';

export const NilSportWidget = ({
  list,
  relatedModel,
}: NilRankingResponseRelatedNilRankingResponseRelatedPagedData) => {
  return (
    <section className={styles.block}>
      <h2>
        {relatedModel?.organizationType &&
          formatOrganizationType(relatedModel?.organizationType)}{' '}
        {relatedModel?.sport?.name} NIL Rankings
      </h2>

      <ol className={styles.list}>
        {list?.map((item) => {
          return (
            item.person && (
              <NilSportWidgetItem {...item} key={item.person?.key} />
            )
          );
        })}
      </ol>

      <div className={styles.links}>
        <Link href="/nil/rankings/player/high-school/football/">
          Complete List
        </Link>
        <Link href="/nil/news/about-on3-nil-valuation-per-post-value/">
          About
        </Link>
      </div>
    </section>
  );
};
