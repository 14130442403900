import type { CoachResponse } from '@on3/api';
import { formatCurrency } from '@on3/ui-lib/utils/formatters';
import { SportIcon } from 'components/Icons/SportIcon';
import { On3Avatar } from 'components/Image/On3Avatar';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';

import styles from './CoachSalariesWidget.module.scss';
import { divisionAbbreviation } from './helpers';

interface ICoachSalariesWidgetItemsProps {
  coachSalary?: CoachResponse;
}
export const CoachSalariesWidgetItems = ({
  coachSalary,
}: ICoachSalariesWidgetItemsProps) => {
  const {
    age,
    defaultAsset,
    defaultSport,
    fullName,
    organization,
    primaryPosition,
    salary,
    slug,
  } = coachSalary ?? {};

  return (
    <li className={styles.itemContainer}>
      <On3Avatar
        altText={defaultAsset?.title ?? 'player headshot'}
        className={styles.headShot}
        domain={defaultAsset?.domain}
        fit="cover"
        height={50}
        radiusFive
        source={defaultAsset?.source ?? ''}
        title={defaultAsset?.title ?? ''}
        width={50}
      />
      <div className={styles.infoContainer}>
        <Text className={styles.positionContainer} variant="caption">
          <span>{divisionAbbreviation(organization?.division ?? 'NA')}</span>
          <SportIcon
            className={styles.sportIcon}
            sportName={defaultSport?.name}
          />
        </Text>

        <TextLink
          className={styles.nameLink}
          href={`/db/coach/${slug}/`}
          underline="none"
        >
          {fullName}
        </TextLink>
        <Text className={styles.position} variant="caption">
          {primaryPosition?.name}
        </Text>
      </div>

      <div className={styles.salaryAgeContainer}>
        <Text className={styles.salary}>
          {salary ? formatCurrency(salary) : '-'}
        </Text>
        <Text className={styles.age} variant="caption">
          Age: {age ?? '-'}
        </Text>
      </div>
    </li>
  );
};
