import {
  CategoryTypes,
  OrganizationType,
  rdbV1CoachesSalariesList,
  rdbV1NilRankingsList,
  rdbV2Nil100List,
  SortByCoachColumn,
  SortDirection,
  v1CategoriesHeadlinesDetail2,
  v1SitesHeadlinesForCategoryTypeDetail,
  v2DealsList,
} from '@on3/api';
import withAuthentication from '@on3/ui-lib/src/hocs/withAuthentication';
import withValidUrl from '@on3/ui-lib/src/hocs/withValidUrl';
import { getFulfilledValue, proxyApi } from '@on3/ui-lib/utils/api';
import type { AxiosError } from 'axios';
import { NILHome, NILPageProps } from 'components/Home/NILHome';
import { PageWrapper } from 'components/Layouts/PageWrapper';
import type { GetServerSideProps } from 'next';
import { usePathname } from 'next/navigation';
import { createCanonicalUrl } from 'utils/meta';

const title = `On3 NIL – Comprehensive NIL and Sports Business News, Data and Insights`;
const description = `Dive into On3's NIL hub for extensive coverage on Name, Image, and Likeness. From high school regulations to college athlete deals, get the latest NIL news, rankings, and expert valuations shaping the future of student-athletes.`;

const NILHomePage = (props: NILPageProps) => {
  const pathname = usePathname();
  const canonical = createCanonicalUrl(pathname);

  return (
    <PageWrapper
      {...props}
      canonical={canonical}
      description={description}
      title={title}
    >
      <NILHome {...props} />
    </PageWrapper>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const nilSiteKey = 368;
  const siteData = await withValidUrl.isValid(ctx, nilSiteKey);
  const currentFootballYear = siteData?.defaultYears?.footballSeasonYear;
  const userData = await withAuthentication.isAuth(ctx);

  try {
    const coachSalaryParams = {
      year: currentFootballYear,
      orderBy: SortByCoachColumn?.Salary,
      direction: SortDirection.DESC,
      limit: 5,
    };

    const nilRankingsParams = {
      sportKey: 1,
      orgType: OrganizationType?.HighSchool,
      limit: 5,
    };

    const nilHomeHeadlinesParams = {
      category: 129,
      limit: 8,
    };
    const collegeHeadlinesParams = {
      siteKey: 368,
      type: CategoryTypes.Sport,
      limit: 8,
    };
    const recHeadlinesParams = {
      siteKey: 368,
      type: CategoryTypes.Recruiting,
      limit: 8,
    };
    const nilUHeadlinesParams = {
      category: 1159,
      limit: 8,
    };
    const nilResourcesHeadlinesParams = {
      category: 89539,
      limit: 5,
    };
    const sportsBusinessHeadlinesParams = {
      category: 535946,
      limit: 8,
    };

    const [
      nilArticles,
      nilNews,
      nilDeals,
      niluArticles,
      nilResources,
      sportsBusiness,
      nil100,
      nilHSrankings,
      nilDealsList,
      coachSalaries,
    ] = await Promise.allSettled([
      v1CategoriesHeadlinesDetail2(proxyApi, nilHomeHeadlinesParams),
      v1SitesHeadlinesForCategoryTypeDetail(proxyApi, collegeHeadlinesParams),
      v1SitesHeadlinesForCategoryTypeDetail(proxyApi, recHeadlinesParams),
      v1CategoriesHeadlinesDetail2(proxyApi, nilUHeadlinesParams),
      v1CategoriesHeadlinesDetail2(proxyApi, nilResourcesHeadlinesParams),
      v1CategoriesHeadlinesDetail2(proxyApi, sportsBusinessHeadlinesParams),
      rdbV2Nil100List(proxyApi, { limit: 5 }),
      rdbV1NilRankingsList(proxyApi, nilRankingsParams),
      v2DealsList(proxyApi, { limit: 3 }),
      rdbV1CoachesSalariesList(proxyApi, coachSalaryParams),
    ]);

    return {
      props: {
        pageType: 'home',
        siteData: siteData || [],
        userData: userData || {},
        nilArticles: getFulfilledValue(nilArticles),
        niluArticles: getFulfilledValue(niluArticles),
        nil100: getFulfilledValue(nil100),
        nilHSrankings: getFulfilledValue(nilHSrankings),
        nilResources: getFulfilledValue(nilResources),
        sportsBusiness: getFulfilledValue(sportsBusiness),
        nilDeals: getFulfilledValue(nilDeals),
        nilNews: getFulfilledValue(nilNews),
        nilDealsList: getFulfilledValue(nilDealsList),
        coachSalaries: getFulfilledValue(coachSalaries),
      },
    };
  } catch (error) {
    const err: AxiosError<unknown> = error as AxiosError<unknown>;

    console.log(err);

    return { props: {} };
  }
};

export default withValidUrl(NILHomePage);
