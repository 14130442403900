import { Icon, INamedIconProps } from 'components/Icon/Icon';

export const On3NilWhiteIcon = ({ ...props }: INamedIconProps) => (
  <Icon
    height="12"
    iconName="On3NilWhite"
    viewBox="0 0 100 24"
    y="25%"
    {...props}
  />
);
