import { SVGProps } from 'react';

export const On3Nil = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      height="24px"
      viewBox="0 0 100 24"
      width="100px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M60.6445 19.4266H62.7193V8.31319C62.7193 8.11474 62.7554 7.95237 62.8275 7.82608C62.8997 7.71783 63.044 7.64567 63.2244 7.64567C63.3688 7.64567 63.477 7.71783 63.5672 7.82608C63.6394 7.93433 63.7476 8.0967 63.8739 8.27711L69.7734 17.7488C70.1342 18.3261 70.5131 18.7952 70.91 19.1379C71.3069 19.4988 71.8481 19.6611 72.5157 19.6611C73.2914 19.6611 73.8868 19.4266 74.2837 18.9395C74.6806 18.4704 74.8971 17.8209 74.8971 17.0271V5.89567H72.8224V17.0812C72.8224 17.2797 72.7682 17.424 72.6961 17.5323C72.6059 17.6405 72.4976 17.6766 72.3533 17.6766C72.245 17.6766 72.1368 17.6225 72.0286 17.5142C71.9203 17.406 71.8121 17.2797 71.7038 17.0993L65.9306 7.77196C65.4976 7.08639 65.0827 6.56319 64.6858 6.20237C64.2708 5.84155 63.7476 5.66113 63.1342 5.66113C62.2322 5.66113 61.6007 5.93175 61.2219 6.47299C60.8249 7.01423 60.6445 7.66371 60.6445 8.42144V19.4266Z"
        data-nil
      />
      <path d="M81.3057 19.4266H83.3804V5.89567H81.3057V19.4266Z" data-nil />
      <path
        d="M89.8016 19.4266H99.9228V17.6225H91.8764V5.89567H89.8016V19.4266Z"
        data-nil
      />
      <path d="M16.6679 15.9859C16.3695 16.8811 15.9432 17.6484 15.3464 18.2879C14.7496 18.9273 13.9822 19.4388 13.0444 19.7799C12.1066 20.1209 10.9982 20.2914 9.71936 20.2914H7.46004C6.18117 20.2914 5.07283 20.1209 4.09236 19.7799C3.15453 19.4388 2.38721 18.9273 1.79041 18.2879C1.19361 17.6484 0.724689 16.8811 0.426288 15.9859C0.127886 15.0907 0 14.0676 0 12.9593V10.2736C0 9.1653 0.127886 8.14221 0.426288 7.20437C0.724689 6.30917 1.15098 5.54185 1.74778 4.85979C2.34458 4.22036 3.1119 3.75144 4.09236 3.41041C5.0302 3.06938 6.13854 2.89886 7.46004 2.89886H9.71936C10.9982 2.89886 12.1066 3.06938 13.0444 3.41041C13.9822 3.75144 14.7496 4.26299 15.3464 4.90242C15.9432 5.54185 16.4121 6.30917 16.6679 7.247C16.9663 8.14221 17.0941 9.1653 17.0941 10.3163V13.0019C17.0941 14.0676 16.9663 15.0907 16.6679 15.9859ZM3.79396 16.9237C4.43339 17.6911 5.54174 18.0321 7.07638 18.0321H10.0178C11.5524 18.0321 12.6607 17.6484 13.3002 16.9237C13.9396 16.1564 14.2806 15.0481 14.2806 13.5561V9.63421C14.2806 8.09958 13.9396 6.99123 13.3002 6.26654C12.6607 5.54185 11.5524 5.15819 10.0178 5.15819H7.07638C5.54174 5.15819 4.43339 5.54185 3.79396 6.26654C3.15453 6.99123 2.8135 8.14221 2.8135 9.63421V13.5561C2.8135 15.0481 3.15453 16.199 3.79396 16.9237Z" />
      <path d="M32.0568 20.632C31.3321 20.632 30.7353 20.4189 30.309 20.0352C29.8827 19.6942 29.4991 19.1826 29.1154 18.5858L23.3179 9.29276C23.19 9.12224 23.1048 8.95173 23.0195 8.86647C22.9769 8.82384 22.9769 8.82384 22.8916 8.82384C22.7637 8.82384 22.7637 8.86647 22.7637 8.86647C22.7211 8.95173 22.6785 9.03699 22.6785 9.2075V20.4189H20.0781V9.29276C20.0781 8.48281 20.2913 7.75812 20.7176 7.20395C21.1438 6.60715 21.8685 6.30875 22.8064 6.30875C23.4884 6.30875 24.0852 6.52189 24.5115 6.90555C24.9378 7.28921 25.3641 7.80075 25.7904 8.52544L31.46 17.6906C31.5453 17.8185 31.6305 17.9464 31.7584 18.0317C31.801 18.0743 31.8437 18.1169 31.8863 18.1169C31.9716 18.1169 31.9715 18.0743 32.0142 18.0743C32.0568 18.0317 32.0568 17.9464 32.0568 17.8185V6.52189H34.6572V17.7333C34.6572 18.5858 34.444 19.2679 34.0177 19.7794C33.5488 20.3336 32.9094 20.632 32.0568 20.632Z" />
      <path d="M50.3448 24H42.629C40.0712 24 37.9824 21.9112 37.9824 19.3535V4.64654C37.9824 2.08881 40.0286 0 42.629 0H50.3448C52.9025 0 54.9913 2.08881 54.9913 4.64654V19.3535C54.9913 21.9112 52.9025 24 50.3448 24Z" />
      <path
        d="M46.935 20.6326C46.8071 20.6326 46.6792 20.6326 46.5939 20.6326H41.3506V17.1797H47.1481C47.7023 17.0944 48.1286 16.7108 48.2564 16.1992C48.2564 16.114 48.2991 16.0287 48.2991 15.9434C48.2991 14.622 45.8692 13.6841 44.8461 12.7889V11.2543C45.8692 10.3591 48.2991 9.33598 48.2991 8.05712C48.2991 7.97186 48.2991 7.88661 48.2564 7.80135C48.1712 7.2898 47.7449 6.90614 47.2334 6.82089H41.3932V3.41058H46.6366C46.7644 3.41058 46.8923 3.41058 47.0202 3.41058C47.1481 3.41058 47.276 3.41058 47.3612 3.41058C49.4501 3.5811 51.1552 5.03048 51.5815 7.03403C51.6241 7.33243 51.6668 7.63083 51.6668 7.92923C51.6668 9.42124 50.8994 10.828 49.5779 11.6806L49.1517 12.0216L49.5779 12.3626C50.8568 13.2152 51.6668 14.622 51.6668 16.114C51.6668 16.4124 51.6241 16.7108 51.5815 17.0092C51.1552 18.9701 49.4501 20.4621 47.3612 20.6326C47.1907 20.6326 47.0628 20.6326 46.935 20.6326Z"
        fill="white"
      />
    </svg>
  );
};
