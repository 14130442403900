import { OrganizationType } from '@on3/api';

const formatOrganizationType = (type: OrganizationType) => {
  switch (type) {
    case OrganizationType.PrepSchool:
      return 'Prep';
    case OrganizationType.HighSchool:
      return 'High School';
    case OrganizationType.JuniorCollege:
      return 'Juco';
    case OrganizationType.Developmental:
      return 'Develop';
    case OrganizationType.College:
      return 'College';
    case OrganizationType.Professional:
      return 'Pro';
    default:
      return type?.replaceAll(/([a-z])([A-Z])/g, '$1 $2');
  }
};

export { formatOrganizationType };
