import type { HeadlineResponse } from '@on3/api';
import { On3NilWhiteIcon } from 'components/Icons/On3NilWhite';
import { SidebarWrapper } from 'components/UI/Layout/Containers/Sidebar/SidebarWrapper';
import { Text } from 'components/UI/Typography/Text';

import { NilResourceItem } from './NilResourceItem';
import styles from './NilResources.module.scss';

interface INilResourcesProps {
  nilArticles: HeadlineResponse[];
}
export const NilResources = ({ nilArticles }: INilResourcesProps) => {
  return (
    <SidebarWrapper>
      <header className={styles.nilResourceHeader}>
        <On3NilWhiteIcon className={styles.nilIcon} />
        <Text className={styles.headerText} component="h2" variant="overline">
          resources
        </Text>
      </header>
      <ul className={styles.articleList}>
        {nilArticles.slice(0, 5).map((article) => {
          return <NilResourceItem {...article} key={article.key} />;
        })}
      </ul>
    </SidebarWrapper>
  );
};
