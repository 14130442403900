import type { NilRankingResponse } from '@on3/api';
import {
  formatCurrency,
  formatMillions,
} from '@on3/ui-lib/src/utils/formatters';
import { SportIcon } from 'components/Icons/SportIcon';
import { On3Avatar } from 'components/Image/On3Avatar';
import { Link } from 'components/Link';

import styles from './NilSportWidgetItems.module.scss';

export const NilSportWidgetItem = ({
  person,
  valuation,
}: NilRankingResponse) => {
  if (!person) {
    return null;
  }

  const { defaultAsset, name, slug, positionAbbreviation, defaultSport } =
    person;

  const profileLink = `/db/${slug}/nil/`;

  return (
    <li className={styles.block}>
      <span className={styles.rank}>{valuation?.groupRank}</span>

      <On3Avatar
        altText={defaultAsset?.title ?? 'player headshot'}
        className={styles.avatar}
        domain={defaultAsset?.domain}
        height={50}
        source={defaultAsset?.source}
        title={defaultAsset?.title ?? ''}
        width={50}
      />

      <div className={styles.meta}>
        <div>
          <Link className={styles.name} href={profileLink} useSiteColor>
            {name}
          </Link>
        </div>
        <div className={styles.divisionPosition}>
          <SportIcon sportName={defaultSport?.name} />
          <span>{positionAbbreviation}</span>
        </div>
      </div>

      <div className={styles.stats}>
        <span className={styles.valuation}>
          {valuation?.valuation ? formatCurrency(valuation?.valuation) : '-'}
        </span>
        <span className={styles.followers}>
          <span>
            {valuation?.followers ? formatMillions(valuation?.followers) : '-'}
            <br />
            followers
          </span>
        </span>
      </div>
    </li>
  );
};
