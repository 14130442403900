import type { CoachResponsePagedData } from '@on3/api';
import { useSite } from '@on3/ui-lib/index';
import { SidebarWrapper } from 'components/UI/Layout/Containers/Sidebar/SidebarWrapper';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';

import styles from './CoachSalariesWidget.module.scss';
import { CoachSalariesWidgetItems } from './CoachSalariesWidgetItems';

export const CoachSalariesWidget = ({ list }: CoachResponsePagedData) => {
  const { defaultYears } = useSite();
  const { footballSeasonYear } = defaultYears;

  return (
    <SidebarWrapper>
      <Text className={styles.title}>Top Coaching Salaries</Text>
      <ol className={styles.sideContainer}>
        {list?.map((coachSalary) => {
          return (
            <CoachSalariesWidgetItems
              coachSalary={coachSalary}
              key={coachSalary.key}
            />
          );
        })}
      </ol>
      <footer className={styles.footer}>
        <TextLink
          className={styles.footerLink}
          color="primary"
          href={`/db/coach/salaries/football/${footballSeasonYear}/`}
          underline="none"
          variant="subtitle1"
        >
          Complete List
        </TextLink>
      </footer>
    </SidebarWrapper>
  );
};
